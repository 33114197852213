export default {
  MetaTitle: {
    Hkfda: 'Hong Kong Furniture and Decoration Trade Association'
  },
  Message: {
    Confirm: 'Ok',
    Cancel: 'Close',
    Load: 'Please click on a publication to view or download the PDF file',
    Fill: 'Please fill out the form below',
    OfflineForm1: 'Please click',
    OfflineForm2: 'for offline application form.',
    Here: 'Here',
    Form: 'Application Form',
    InquiryFill: 'Should you have any inquiry, please fill in the form below:'
  },
  DeliveryAddress: {
    TitleD: 'Contact Us',
    Address: 'Corresponding Address'
  },
  Order: {
    OrderNumber: 'Order Number',
    OrderDateTime: 'Order Date & Time',
    TotalPrice: 'TotalPrice',
    OrderStatus: 'Order Status',
    Paid: 'Paid',
    Unpaid: 'Unpaid',
    PayNow: 'PayNow'
  },
  RegNPay: {
    Form: 'Form',
    BackText: 'Back',
    UploadButtonText: 'Upload files(jpg, png, pdf)',
    UploadingText: 'Uploading...',
    ConfirmText: 'OK',
    UploadSuccessfulText: 'Upload Successful',
    UploadFailText: 'Upload failed',
    NoFileText: 'No file selected',
    UploadLengthText: 'You can only upload {0} files at most',
    UploadSizeText: 'You can only upload {0} M at most',
    PleaseSelect: 'Please Select',
    SubRecord: 'Submission Record',
    ReferenceNum: 'Reference Number',
    SubmissionDate: 'Submission Date',
    Print: 'Print',
    PlsPayWay: 'Please select the mode of payment',
    PreviewTitleText: 'Please confirm your information again.',
    Enquiry: 'Enquiry'
  },
  Display: {
    SuccessCase: 'Successful Case',
    GeneralQuery: 'General Query',
    OfficeHours: 'Office Hours',
    Hour0: 'Monday to Friday：',
    Hour1: '9:00 a.m. to 12:00 p.m.,',
    Hour2: 'From 1:30 p.m. to 6 p.m',
    Hour3: 'Closed on Saturdays, Sundays and public holidays',
    AddrInfo: 'Rm 611, Blk B, Wah Luen lnd. Ctr.,15-21 Wong Chuk Yeung St.,Fo Tan, N.T., Hong Kong.',
    Sponsor: 'Sponsor',
    Referred: 'Referred by (if applicable)'
  },
  Action: {
    ViewMore: 'READ MORE',
    SignUp: 'Sign Up',
    ReturnHomePage: 'Return Home Page',
    OrderDetails: 'Order Details'
  },
  Cms: {
    PlsChoose: 'Please click to select the content you want to view :',
    AboutUs: 'About Us',
    Events: 'Events',
    Anniversary: '60th Anniver-sary',
    Scholarship: 'HKF&DA Scholar-ship',
    CIFF: 'CIFF',
    Media: 'Media'
  },
  payment: {
    PaymentSuccessTips: 'Payment Result : Success',
    PaymentFailTips: 'Payment Result : Fail'
  },
  Search: {
    SearchResult: 'Search Results'
  }
};
