







import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {
    InsFooter: () => import('@/components/business/footer/InsFooter.vue')
  }
})
export default class layout extends Vue {
  get isMobile () {
    return this.$store.state.isMobile;
  }
}
