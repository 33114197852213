export default {
  MetaTitle: {
    Hkfda: '香港傢俬裝飾廠商總會'
  },
  Message: {
    Confirm: '確認',
    Cancel: '關閉',
    Load: '請點擊刊物即時閱讀或下載相關 PDF 檔',
    Fill: '請填寫以下所有部分',
    OfflineForm1: '請按',
    OfflineForm2: '索取離線申請表',
    Here: '這裡',
    Form: '申請表',
    InquiryFill: '如有任何疑問，請填寫以下表格：'
  },
  DeliveryAddress: {
    TitleD: '聯絡方式',
    Address: '通訊地址'
  },
  Order: {
    OrderNumber: '訂單編號',
    OrderDateTime: '訂單日期&時間 ',
    TotalPrice: '總價',
    OrderStatus: '訂單狀態',
    Paid: '已付款',
    Unpaid: '待付款',
    PayNow: '立即支付'
  },
  RegNPay: {
    Form: '表格',
    BackText: '返回',
    UploadButtonText: '上傳文件(jpg, png, pdf)',
    UploadingText: '正在上傳',
    ConfirmText: '確定',
    UploadSuccessfulText: '上傳成功',
    UploadFailText: '上傳失敗',
    NoFileText: '沒有選中文件',
    UploadLengthText: '最多只能上載{0}檔案',
    UploadSizeText: '最多只能上傳{0}M',
    PleaseSelect: '請選擇',
    SubRecord: '提交紀錄',
    ReferenceNum: '參考編號',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '請選擇付款方式',
    PreviewTitleText: '請再次確認頁面',
    Enquiry: '查詢'
  },
  Display: {
    SuccessCase: '成功個案',
    GeneralQuery: '壹般查詢',
    OfficeHours: '辦公時間',
    Hour0: '星期壹至五：',
    Hour1: '早上9時至下午12時,',
    Hour2: '下午1時30分至晚上6時',
    Hour3: '星期六、日及公眾期假休息',
    AddrInfo: 'Rm 611, Blk B, Wah Luen lnd. Ctr.,15-21 Wong Chuk Yeung St.,Fo Tan, N.T., Hong Kong.',
    Sponsor: '贊助',
    Referred: '申請入會介紹人（如適用）'
  },
  Action: {
    ViewMore: '查看更多',
    SignUp: '立即報名',
    ReturnHomePage: '返回首頁',
    OrderDetails: '訂單詳情'
  },
  Cms: {
    PlsChoose: '請點擊選擇您想要查看的內容：',
    AboutUs: '關於我們',
    Events: '最新活動',
    Anniversary: '六十週年',
    Scholarship: 'HKF&DA家具設計獎學金',
    CIFF: '中國國際家具博覽會',
    Media: '媒體報道'
  },
  payment: {
    PaymentSuccessTips: '支付結果： 成功',
    PaymentFailTips: '支付結果： 失敗'
  },
  Search: {
    SearchResult: '搜索結果'
  }
};
