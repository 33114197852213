export default {
  Message: {
    Confirm: '确认',
    Cancel: '关闭',
    Load: '请点击刊物即时阅读或下载相关 PDF 档'
  },
  DeliveryAddress: {
    Address: '地址'
  },
  Order: {
    OrderNumber: '订单编号',
    OrderDateTime: '订单日期&时间 ',
    TotalPrice: '总价',
    OrderStatus: '订单状态',
    Paid: '已付款',
    Unpaid: '待付款',
    PayNow: '立即支付'
  },
  RegNPay: {
    BackText: '返回',
    UploadButtonText: '上传文件(jpg, png, pdf)',
    UploadingText: '正在上传',
    ConfirmText: '确定',
    UploadSuccessfulText: '上传成功',
    UploadFailText: '上传失败',
    NoFileText: '没有选中文件',
    UploadLengthText: '最多只能上载{0}文件',
    UploadSizeText: '最多只能上传{0}M',
    PleaseSelect: '请选择',
    SubRecord: '提交纪录',
    ReferenceNum: '参考编号',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '请选择付款方式',
    Enquiry: '查询'
  },
  Display: {
    SuccessCase: '成功个案',
    GeneralQuery: '一般查询',
    OfficeHours: '办公时间',
    Hour0: '星期一至五：',
    Hour1: '早上9时至下午12时,',
    Hour2: '下午1时30分至晚上6时',
    Hour3: '星期六、日及公众期假休息',
    AddrInfo: '长沙湾荔枝角道 777号田氏企业中心 3楼 306室 (长沙湾港铁站 B出口)'
  },
  Action: {
    ViewMore: '查看更多',
    SignUp: '立即报名'
  },
  Cms: {
    PlsChoose: '请点击选择您想要查看的内容：'
  },
  Search: {
    SearchResult: '搜索结果'
  }
};
